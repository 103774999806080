import dayjs from "dayjs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

// Internal
import { API_ENTRYPOINT } from "@/utils/fetch";
import { httpRequests } from "./_shared";
import { IAttendance } from "@/types/attendance-sheet.types";
import {
  ChildCertificate,
  GetChildCertificatesDto,
  SubmitAttendanceForFamilyDto,
  SubmitAttendanceForProviderDto,
} from "./__types/attendance";

interface GetAttendanceListDto {
  startDate?: string;
  endDate: string;
  familyId?: string;
  providerId?: string;
}

interface GetAttendanceDetailsDto {
  attendanceId: string;
  platform: string;
}

// QUERY KEYS
export const attendanceQueryKeys = {
  all: ["attendance"] as const,
  attendanceLists: () => [...attendanceQueryKeys.all, "list"] as const,
  attendanceList: (dto: GetAttendanceListDto) =>
    [...attendanceQueryKeys.attendanceLists(), dto] as const,
  attendanceDetails: (dto: any) =>
    [...attendanceQueryKeys.attendanceLists(), dto] as const,
  childCertificateLists: () => [...attendanceQueryKeys.all, "list"] as const,
  childCertificateList: () => (dto: GetChildCertificatesDto) =>
    [...attendanceQueryKeys.childCertificateLists(), dto] as const,
};

/*
 * Get attendance list
 */
export const _getAttendanceList = async ({
  startDate,
  endDate,
  familyId,
  providerId,
}: GetAttendanceListDto) => {
  return httpRequests.get<IAttendance[]>(
    `${API_ENTRYPOINT}/${providerId ? "providers" : "families"}/${
      providerId || familyId
    }/attendance?end=${endDate}${!providerId ? `&start=${startDate}` : ""}`
  );
};
// react-query hook
export const useGetAttendanceList = (dto: GetAttendanceListDto) => {
  return useQuery<IAttendance[], Error>({
    queryKey: attendanceQueryKeys.attendanceList(dto),
    queryFn: async () => {
      const data = await _getAttendanceList(dto);

      return data;
    },
    enabled: !!dto.endDate,
    retry: 1,
  });
};

/*
 * Get attendance list
 */
export const _getAttendanceDetails = async ({
  attendanceId,
  platform,
}: GetAttendanceDetailsDto) => {
  return httpRequests.get<IAttendance[]>(
    `${API_ENTRYPOINT}/attendance/${attendanceId}?platform=${platform}`
  );
};
// react-query hook
export const useGetAttendanceDetails = (dto: GetAttendanceDetailsDto) => {
  return useQuery<IAttendance[], Error>({
    queryKey: attendanceQueryKeys.attendanceDetails(dto),
    queryFn: async () => {
      const data = await _getAttendanceDetails(dto);

      return data;
    },
    retry: 1,
  });
};

/*
 * Submit provider attendance
 */
export const _submitAttendanceForProvider = (
  dto: SubmitAttendanceForProviderDto
) =>
  httpRequests.post<unknown>(
    `${API_ENTRYPOINT}/providers/${dto.providerId}/attendance/${dto.attendanceId}`,
    dto
  );
export const useSubmitAttendanceForProvider = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dto: SubmitAttendanceForProviderDto) => {
      const data = await _submitAttendanceForProvider(dto);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: attendanceQueryKeys.attendanceLists(),
      });
    },
  });
};

/*
 * Submit family attendance
 */
export const _submitAttendanceForFamily = (dto: SubmitAttendanceForFamilyDto) =>
  httpRequests.post<unknown>(
    `${API_ENTRYPOINT}/families/${dto.familyId}/attendance/${dto.attendanceId}`,
    dto
  );
export const useSubmitAttendanceForFamily = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dto: SubmitAttendanceForFamilyDto) => {
      const data = await _submitAttendanceForFamily(dto);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: attendanceQueryKeys.attendanceLists(),
      });
    },
  });
};

/*
 * Get child's certificates
 */
export const _getChildCertificates = async ({
  childId,
  platform,
  providerId,
}: GetChildCertificatesDto) => {
  return httpRequests.get<ChildCertificate[]>(
    `${API_ENTRYPOINT}/providers/${providerId}/children/${childId}/certificates?platform=${platform}`
  );
};
// react-query hook
export const useGetChildCertificates = (dto: GetChildCertificatesDto) => {
  return useQuery<ChildCertificate[], Error>({
    queryKey: attendanceQueryKeys.attendanceDetails(dto),
    queryFn: async () => {
      const data = await _getChildCertificates(dto);

      const _startDate = new Date(dto.startDate);

      // sort by dateCertBegins, earlier should be at the top
      return data
        .filter((d) => {
          return (
            dayjs(_startDate).isBefore(d.dateCertEnds) ||
            dayjs(_startDate).isSame(d.dateCertEnds)
          );
        })
        .sort((a, b) => {
          return (
            new Date(a.dateCertBegins).getTime() -
            new Date(b.dateCertBegins).getTime()
          );
        });
    },
    retry: 1,
  });
};

export const _generateAttendanceSheetLink = async (
  payload: GetAttendanceListDto
) => {
  return httpRequests.post<string>(
    `${API_ENTRYPOINT}/attendance/generate-link`,
    payload
  );
};
export const useGenerateAttendanceSheetLink = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: GetAttendanceListDto) => {
      const data = await _generateAttendanceSheetLink(payload);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: attendanceQueryKeys.attendanceLists(),
      });
    },
  });
};
