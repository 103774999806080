import { useEffect } from "react";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";

// Internal
import { useUrlParams } from "./hooks/useUrlParams";
import Home from "./pages/home/home.tsx";
import Attendance from "./pages/attendance/attendance.tsx";
import { loadNewRelicSessionReplayScript } from "./utils/newrelic-sesion-replay.ts";
import LinkGenerator from "./components/LinkGenerator.tsx";

// Styles
import "./styles/styles.scss";

// Antd
import { Layout } from "antd";

// MISC
import dayjs from "dayjs";
import ReactGA from "react-ga";

const { Header, Content } = Layout;

const isDevOrStaging = ["dev", "staging"].includes(
  import.meta.env.VITE_SITE_ENV
);

export default function App() {
  const location = useLocation();

  // Search params
  const [searchParams, setSearchParams] = useSearchParams();
  const dataParam = searchParams.get("data");
  const { familyId, providerId, isProviderView, startDate, endDate } =
    useUrlParams({
      searchParams,
    });

  useEffect(() => {
    if (searchParams.get("end") === null && endDate) {
      const _splitEndDate = endDate
        .split("-")
        .map((x: string) => parseInt(x, 10));

      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        end: dayjs(new Date(_splitEndDate[0], _splitEndDate[1] - 1))
          .endOf("month")
          .format("YYYY-MM"),
      });
    }
  }, [endDate, searchParams, setSearchParams]);

  // Load New Relic Session Replay Script
  useEffect(() => {
    // Load New Relic Session Replay Script
    loadNewRelicSessionReplayScript();

    // Override JIRA WIDGET styles
    overrideJiraWidgetStyles();
  }, []);

  // Google Analytics
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location.pathname, location.search]);

  /*
   * If we have all the fields we need, then we can render the app.
   */
  if (startDate && endDate && (providerId || familyId)) {
    return (
      <Layout className="flex flex-col min-h-screen">
        <div className="flex-grow">
          <Header
            className={`px-4 sm:px-12${isDevOrStaging ? " bg-[#fbad3b] border-b border-solid border-b-[#00142a4a]" : ""}`}
            style={{ height: "100px", display: "flex", alignItems: "center" }}
          >
            <div>
              <img
                src="/images/ccaoa-logo.png"
                alt="Logo"
                className="table h-20 mr-6"
              />
            </div>

            <div className="flex-grow text-white text-right">
              <h1
                className={`text-base font-semibold tracking-wide m-0${isDevOrStaging ? " text-[#000]" : ""}`}
              >
                {isProviderView ? "Provider" : "Family"} Attendance
              </h1>

              {isDevOrStaging && (
                <div className="h-4 leading-none text-[#000] opacity-70">
                  {import.meta.env.VITE_SITE_ENV === "dev" ? "DEV" : "STAGING"}{" "}
                  environment
                </div>
              )}
            </div>
          </Header>

          <div className="pb-[200px]">
            <Routes>
              <Route index element={<Home />} />
              <Route
                path="/attendance/:attendanceId"
                element={<Attendance />}
              />
            </Routes>
          </div>
        </div>
      </Layout>
    );
  } else if (!!dataParam) {
    /*
     * If link is invalid, then we can render an error message.
     */
    return <LinkSeemsInvalidError />;
  } else {
    return <LinkGenerator />;
  }
}

function LinkSeemsInvalidError() {
  return (
    <Layout className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <Header
          className={`px-4 sm:px-12${isDevOrStaging ? " bg-[#fbad3b] border-b border-solid border-b-[#00142a4a]" : ""}`}
          style={{ height: "100px", display: "flex", alignItems: "center" }}
        >
          <div>
            <img
              src="/images/ccaoa-logo.png"
              alt="Logo"
              className="table h-20 mr-6"
            />
          </div>

          <div className="flex-grow text-white text-right">
            {isDevOrStaging && (
              <div className="h-4 leading-none text-[#000] opacity-70">
                {import.meta.env.VITE_SITE_ENV === "dev" ? "DEV" : "STAGING"}{" "}
                environment
              </div>
            )}
          </div>
        </Header>

        <Content>
          <div style={{ padding: "48px", maxWidth: "1800px", margin: "auto" }}>
            Link seems to be invalid
          </div>
        </Content>
      </div>
    </Layout>
  );
}

/*
 * Override JIRA WIDGET styles
 */
const overrideJiraWidgetStyles = () => {
  /*
   * Inject styles into JIRA WIDGET iframe
   */
  const injectStyles = (iframe: HTMLIFrameElement) => {
    if (iframe && iframe.contentDocument) {
      const contentDocument = iframe.contentDocument;

      const style = contentDocument.createElement("style");
      style.appendChild(
        contentDocument.createTextNode(
          ".help-form { padding-bottom: 200px !important; }"
        )
      );

      contentDocument.head.appendChild(style);
    }
  };
  let iframeTries = 0;
  const _iframeInterval = setInterval(() => {
    const _iframe = document.querySelector("iframe");
    if (_iframe) {
      clearInterval(_iframeInterval);
      injectStyles(_iframe as HTMLIFrameElement);
    }

    // If we can't find the iframe after 5 tries, then stop trying
    if (iframeTries >= 5) {
      clearInterval(_iframeInterval);
    }

    iframeTries++;
  }, 2000);
};
