import { ReactNode } from "react";

export default function Container({
  children,
  className,
}: {
  children: ReactNode;
  className: string;
}) {
  return (
    <div
      className={`p-4 sm:p-12 m-auto max-w-[1800px] ${className ? ` ${className}` : ""}`}
    >
      {children}
    </div>
  );
}
